import React from "react";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import base_url from "../App/App";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {
  
  setField(e) {
    this.setState({ [e.target.email]: e.target.value })
    this.setState({ [e.target.password]: e.target.value })
  }
  state = {
    email: '',
    password: '',
    submitted: false
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true }); 
    let formData = new FormData();
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)
      axios({
        method: 'post',
        url: base_url+'api/contact.php',
        data: formData,
        config: { headers: { "Access-Control-Allow-Origin": "*", 'Content-Type': 'multipart/form-data' } }
      })
        .then((response) => { 
          if (response.data.status === '200') {
           // console.log(response.data);
            this.props.showAuthLoader();
            //let val = response.data;//console.log(val);
            let val = { email: "demo@example.com", password: "demo#123", status: "200", id: response.data.id, type: response.data.type, signin_name: response.data.name, access: response.data.access };
           this.props.userSignIn(val);
           // let authUser= 'vUAqPRNWgYdS2DyJNAC1lV5KpJF2';
           //this.props.history.push('/Driver-List');
          
          } else{
             message.error('Email ID and password are mismatched!'.toString())
          }
          
        })
        .catch((response) => {
          console.log(response)
        });
    
    
  };
  
  componentDidUpdate() {
    
    var location = window.location.href;
    var loc = location.split("//");
    var domain = loc[1].split('.');
    var domain_name = domain[0];
    if (domain_name === 'user'){
      document.title = "Ridehome - User"
    } else if(domain_name === 'driver') {
      document.title = "Ridehome - Driver"
    } else if(domain_name === 'business') {
      document.title = "Ridehome - Employer"
    } else if(domain_name === 'employee') {
      document.title = "Ridehome - Employee"
    } else if(domain_name === 'admin') {
      document.title = "Ridehome - Admin"
    } else {
     document.title = "Ridehome"
    }
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }// console.log(this.props.authUser);
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;
    //const { username, password, submitted } = this.state;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src="https://cdn.dribbble.com/users/1488946/screenshots/8051692/media/2ff30f1ff211d3f2fb4af01f469d2d5a.png" alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: "",
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email" name="email"
                      onChange={e => this.setState({ email: e.target.value })} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: "",
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input type="password" placeholder="Password" name="password" onChange={e => this.setState({ password: e.target.value })} />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept" /></Checkbox>
                  )}
                  <span className="gx-signup-form-forgot gx-link"><IntlMessages
                    id="appModule.termAndCondition" /></span>
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  {/*<span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signup"><IntlMessages
                    id="app.userAuth.signUp" /></Link>*/}
                </FormItem>
                <div className="gx-flex-row gx-justify-content-between">
                  <span>or connect with</span>
                  <ul className="gx-social-link">
                    <li>
                      <Icon type="google" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userGoogleSignIn();
                      }} />
                    </li>
                    <li>
                      <Icon type="facebook" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userFacebookSignIn();
                      }} />
                    </li>
                    <li>
                      <Icon type="github" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userGithubSignIn();
                      }} />
                    </li>
                    <li>
                      <Icon type="twitter" onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userTwitterSignIn();
                      }} />
                    </li>
                  </ul>
                </div>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {
              showMessage ? 
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedNormalLoginForm);
