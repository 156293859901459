import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyDTUjaSP9NxXmCSI2kMQxJMSxx8U6dBdcY',
  authDomain: 'ridehome-c76ea.firebaseapp.com',
  databaseURL: 'https://ridehome-c76ea.firebaseio.com',
  projectId: 'ridehome-c76ea',
  storageBucket: 'ridehome-c76ea.appspot.com',
  messagingSenderId: '850036078166'
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
