import React, { Component } from "react";

import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import { userSignIn, userSignOut, userSignOutSuccess } from "appRedux/actions/Auth";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import base_url from "../../App/App";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log(this.props.access);
    let access = this.props.access;
    if (access === 'undefined' || access === '') {
      access = '';
    }
    //console.log(access.indexOf("Add Driver"));
    // console.log(access.indexOf("Add Driver") > -1);
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname, access } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    const { SubMenu } = Menu;
    return (<Auxiliary>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
          <UserProfile />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar sidebar-layout">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">



            <Menu.Item key="sample">
              <Link to="/Dashboard"><i className="icon icon-dasbhoard" />
                <IntlMessages id="sidebar.samplePage" /></Link>
            </Menu.Item>


            {
              ((access && access.indexOf("Driver List") > -1) || (access && access.indexOf("Accept/Reject") > -1) || (access && access.indexOf("Block/Unblock") > -1) || this.props.signin_type === 'Super_Admin')
                ?
            <SubMenu className="Subb-Menu"
              key="sub4"
              title={
                <span>
                  <i className="icon icon-avatar" />
                  <span>Driver Management</span>
                </span>
              }
            >
              {((access && access.indexOf("Driver List") > -1) || this.props.signin_type === 'Super_Admin')
                ?
              <Menu.Item key="adddriver"  >
                <Link to="/Driver-List"><i className="icon icon-avatar" />
                  <IntlMessages id="sidebar.ListDriver" /></Link>
              </Menu.Item>
              :
              ''
              }


                  {((access && access.indexOf("Accept/Reject") > -1) || this.props.signin_type === 'Super_Admin')
                    ?
              <Menu.Item key="acceptdriver">
                <Link to="/Accept-Reject-Driver"><i className="icon icon-avatar" />
                  <IntlMessages id="sidebar.NewDrivers" /></Link>
              </Menu.Item>

                    :
                    ''
                  }

                  {((access && access.indexOf("Block/Unblock") > -1) || this.props.signin_type === 'Super_Admin')
                    ?

              <Menu.Item key="blockdriver">
                <Link to="/Block-UnBlock-Driver"><i className="icon icon-avatar" />
                  <IntlMessages id="sidebar.ApproveBlockDriver" /></Link>
              </Menu.Item>

                    :
                    ''
                  }

            </SubMenu>

                :
                ''
            }
              

            {((access && access.indexOf("Passenger List") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub5"
                title={
                  <span>
                    <i className="icon icon-user" />
                    <span>User Management</span>
                  </span>
                }
              >
                {((access && access.indexOf("Passenger List") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="passengerlist">
                    <Link to="/Passenger-List"><i className="icon icon-user" />
                      <IntlMessages id="sidebar.UserList" /></Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Corporate Users") > -1) || (access && access.indexOf("Company List") > -1) || (access && access.indexOf("Transaction Details") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub23"
                title={
                  <span>
                    <i className="icon icon-company" />
                    <span>Corporate Users</span>
                  </span>
                }
              >
               
                {((access && access.indexOf("Company List") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="companylist">
                    <Link to="/Company-List"><i className="icon icon-company" />
                      <IntlMessages id="sidebar.CompanyList" /></Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Transaction Details") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="transactiondetails">
                    <Link to="/Transaction-Details"><i className="icon icon-company" />
                      <IntlMessages id="sidebar.TransactionDetails" /></Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }
            

            {((access && access.indexOf("Add User Group") > -1) || (access && access.indexOf("Manage User Group") > -1) || (access && access.indexOf("Add Manager") > -1) || (access && access.indexOf("Manager List") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub18"
                title={
                  <span>
                    <i className="icon icon-profile" />
                    <span>Admin Groups</span>
                  </span>
                }
              >
                {((access && access.indexOf("Add User Group") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="addusergroup">
                    <Link to="/Add-User-Group"><i className="icon icon-profile" />
                      <IntlMessages id="sidebar.AddAdminGroup" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Manage User Group") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="manageusergroup">
                    <Link to="/Manage-User-Group"><i className="icon icon-profile" />
                      <IntlMessages id="sidebar.ManageAdminGroup" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Add Manager") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="addsubadmin">
                    <Link to="/Add-Subadmin"><i className="icon icon-profile" />
                      <IntlMessages id="sidebar.AddSubadmin" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Manager List") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="managesubadmin">
                    <Link to="/Manage-Subadmin"><i className="icon icon-profile" />
                      <IntlMessages id="sidebar.ManageSubadmin" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Cab Type") > -1) || this.props.signin_type === 'Super_Admin')
              ?

              <SubMenu className="Subb-Menu"
                key="sub6"
                title={
                  <span>
                    <i className="icon icon-ConfigProvider" />
                    <span>Cab Management</span>
                  </span>
                }
              >
                {((access && access.indexOf("Cab Type") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="cabtype">
                    <Link to="/CabType"><i className="icon icon-ConfigProvider" />
                      <IntlMessages id="sidebar.CabType" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Time Management") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub7"
                title={
                  <span>
                    <i className="icon icon-timepicker" />
                    <span>Time & Distance</span>
                  </span>
                }
              >
                {((access && access.indexOf("Time Management") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="timemanagement">
                    <Link to="/Time-Management"><i className="icon icon-timepicker" />
                      <IntlMessages id="sidebar.TimeManagement" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Fare Settings") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub8"
                title={
                  <span>
                    <i className="icon icon-diamond" />
                    <span>Fare Management</span>
                  </span>
                }
              >
                {((access && access.indexOf("Fare Settings") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="commissionpercentage">
                    <Link to="/Commission-Percentage"><i className="icon icon-diamond" />
                      <IntlMessages id="sidebar.CommissionPercentage" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Add Coupon") > -1) || (access && access.indexOf("Coupon List") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub9"
                title={
                  <span>
                    <i className="icon icon-pricing-table" />
                    <span>Promocode Management</span>
                  </span>
                }
              >
                {((access && access.indexOf("Add Coupon") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="coupongeneration">
                    <Link to="/Coupon-Generation"><i className="icon icon-pricing-table" />
                      <IntlMessages id="sidebar.CouponGeneration" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Coupon List") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="couponlist">
                    <Link to="/Coupon-List"><i className="icon icon-pricing-table" />
                      <IntlMessages id="sidebar.CouponList" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Active Trips") > -1) || (access && access.indexOf("Completed Trips") > -1) || (access && access.indexOf("Booked Trips") > -1) || (access && access.indexOf("Driver Location") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub10"
                title={
                  <span>
                    <i className="icon icon-location" />
                    <span>Trip Details</span>
                  </span>
                }
              >
                {((access && access.indexOf("Active Trips") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="activetrips">
                    <Link to="/Active-Trips"><i className="icon icon-location" />
                      <IntlMessages id="sidebar.ActiveTrips" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Completed Trips") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="completedtrips">
                    <Link to="/Completed-Trips"><i className="icon icon-location" />
                      <IntlMessages id="sidebar.CompletedTrips" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Booked Trips") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="bookedtrips">
                    <Link to="/Booked-Trips"><i className="icon icon-location" />
                      <IntlMessages id="sidebar.BookedTrips" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Driver Location") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="heatmap">
                    <Link to="/Driver-Location-Map"><i className="icon icon-location" />
                      <IntlMessages id="sidebar.DriverLocationMap" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Payment Report") > -1) || (access && access.indexOf("Active Trip Report") > -1) || (access && access.indexOf("Cancelled Trips") > -1) || (access && access.indexOf("Request/Reject Trips") > -1) || (access && access.indexOf("Driver Profile") > -1) || (access && access.indexOf("Driver CDC Report") > -1) || (access && access.indexOf("User CDC Report") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub11"
                title={
                  <span>
                    <i className="icon icon-stats" />
                    <span>Report</span>
                  </span>
                }
              >
                {((access && access.indexOf("Payment Report") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="paymentreport">
                    <Link to="/Payment-Report"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.PaymentReport" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Active Trip Report") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="activetripreport">
                    <Link to="/Active-Trips-History"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.ActiveTripsHistory" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Cancelled Trips") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="cancelledtrips">
                    <Link to="/Cancelled-Trips"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.CancelledTrips" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Request/Reject Trips") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="triprequestreject">
                    <Link to="/Trip-Request-Reject"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.TripRequestReject" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Driver Profile") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="driverprofilereport">
                    <Link to="/Driver-Profile-Report"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.DriverProfileReport" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Driver CDC Report") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="drivercdcreport">
                    <Link to="/Driver-CDC-Report"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.DriverCDCReport" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("User CDC Report") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="usercdcreport">
                    <Link to="/User-CDC-Report"><i className="icon icon-stats" />
                      <IntlMessages id="sidebar.UserCDCReport" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Overall Statements") > -1) || (access && access.indexOf("Driver Statements") > -1) || (access && access.indexOf("Passenger Statements") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub12"
                title={
                  <span>
                    <i className="icon icon-transfer" />
                    <span>Statements</span>
                  </span>
                }
              >
                {((access && access.indexOf("Overall Statements") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="overallridestatement">
                    <Link to="/Overall-Ride-Statement"><i className="icon icon-transfer" />
                      <IntlMessages id="sidebar.OverallRideStatement" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Driver Statements") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="driverstatement">
                    <Link to="/Driver-Statement"><i className="icon icon-transfer" />
                      <IntlMessages id="sidebar.DriverStatement" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Passenger Statements") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="userstatement">
                  {(this.props.signin_type === 'User'||this.props.signin_type === 'Employee')
                    ?
                    <Link to="/Passengerwise-Statement"><i className="icon icon-transfer" />
                      <IntlMessages id="sidebar.TripsStatement" />
                    </Link>
                    :
                    <Link to="/Passenger-Statement"><i className="icon icon-transfer" />
                      <IntlMessages id="sidebar.PassengerStatement" />
                    </Link>
                  }
                    
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Driver Updates") > -1) || (access && access.indexOf("Passenger KYC verify") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub13"
                title={
                  <span>
                    <i className="icon icon-profile2" />
                    <span>Profile Updates</span>
                  </span>
                }
              >
                {((access && access.indexOf("Driver Updates") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="driverrequest">
                    <Link to="/Driver-Update-Request"><i className="icon icon-profile2" />
                      <IntlMessages id="sidebar.DriverRequest" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Passenger KYC verify") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="userkycverify">
                    <Link to="/User-KYC-Verify"><i className="icon icon-profile2" />
                      <IntlMessages id="sidebar.UserKycVerify" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Passenger Rating") > -1) || (access && access.indexOf("Driver Rating") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub14"
                title={
                  <span>
                    <i className="icon icon-star" />
                    <span>Rating & Reviews</span>
                  </span>
                }
              >
                {((access && access.indexOf("Passenger Rating") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="userratings">
                    <Link to="/User-Rating-Reviews"><i className="icon icon-star" />
                      <IntlMessages id="sidebar.UserRatingReviews" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Driver Rating") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="driverratings">
                    <Link to="/Driver-Rating-Reviews"><i className="icon icon-star" />
                      <IntlMessages id="sidebar.DriverRatingReviews" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Send Notification") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub15"
                title={
                  <span>
                    <i className="icon icon-notification" />
                    <span>Notifications</span>
                  </span>
                }
              >
                {((access && access.indexOf("Send Notification") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="sendnotifications">
                    <Link to="/Send-Notifications"><i className="icon icon-notification" />
                      <IntlMessages id="sidebar.SendNotifications" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {((access && access.indexOf("Dispute Type") > -1) || (access && access.indexOf("Dispute Request") > -1) || this.props.signin_type === 'Super_Admin')
              ?
              <SubMenu className="Subb-Menu"
                key="sub16"
                title={
                  <span>
                    <i className="icon icon-ticket-new" />
                    <span>Dispute</span>
                  </span>
                }
              >
                {((access && access.indexOf("Dispute Type") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="disputetype">
                    <Link to="/Dispute-Type"><i className="icon icon-ticket-new" />
                      <IntlMessages id="sidebar.DisputeType" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

                {((access && access.indexOf("Dispute Request") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
                  <Menu.Item key="disputerequest">
                    <Link to="/Dispute-Request"><i className="icon icon-ticket-new" />
                      <IntlMessages id="sidebar.DisputeRequest" />
                    </Link>
                  </Menu.Item>
                  :
                  ''
                }

              </SubMenu>
              :
              ''
            }

            {(this.props.signin_type === 'Company')
           ?
           <SubMenu className="Subb-Menu"
              key="sub20"
              title={
                <span>
                  <i className="icon icon-user" />
                  <span>Manage Employees</span>
                </span>
              }
            >
              <Menu.Item key="addemployee">
                <Link to="/Add-Employee"><i className="icon icon-user" />
                  <IntlMessages id="sidebar.AddEmployee" />
                </Link>
              </Menu.Item>
              <Menu.Item key="manageemployee">
                <Link to="/Manage-Employee"><i className="icon icon-user" />
                  <IntlMessages id="sidebar.ManageEmployee" />
                </Link>
              </Menu.Item>
            </SubMenu>
           :
           ''
          } 

          {(this.props.signin_type === 'Company')
          ?
           <SubMenu className="Subb-Menu"
              key="sub21"
              title={
                <span>
                  <i className="icon icon-card" />
                  <span>Manage Credit Cards</span>
                </span>
              }
            >
              <Menu.Item key="addnewcard">
                <Link to="/Add-NewCard"><i className="icon icon-card" />
                  <IntlMessages id="sidebar.AddNewCard" />
                </Link>
              </Menu.Item>
              <Menu.Item key="managecards">
                <Link to="/Manage-Cards"><i className="icon icon-card" />
                  <IntlMessages id="sidebar.ManageCards" />
                </Link>
              </Menu.Item>
            </SubMenu>
            :
            ''
           }

           {(this.props.signin_type === 'Company')
           ?
           <SubMenu className="Subb-Menu"
              key="sub22"
              title={
                <span>
                  <i className="icon icon-ConfigProvider" />
                  <span>Transactions</span>
                </span>
              }
            >
             <Menu.Item key="transaction">
                <Link to="/Transaction"><i className="icon icon-ConfigProvider" />
                  <IntlMessages id="sidebar.Transaction" />
                </Link>
              </Menu.Item>
            </SubMenu> 
            :
            ''
           }

            {((access && access.indexOf("Account Setting") > -1) || (access && access.indexOf("Cab Models") > -1) || (access && access.indexOf("Cab Makes") > -1) || this.props.signin_type === 'Super_Admin')
              ?

            <SubMenu className="Subb-Menu"
              key="sub17"
              title={
                <span>
                  <i className="icon icon-setting" />
                  <span>Settings</span>
                </span>
              }
            >
               

              {((access && access.indexOf("Cab Makes") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
              <Menu.Item key="cabmakes">
                <Link to="/Cab-Makes"><i className="icon icon-setting" />
                  <IntlMessages id="sidebar.CabMakes" />
                </Link>
              </Menu.Item>
              :
                ''
              }

              {((access && access.indexOf("Cab Models") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
              <Menu.Item key="cabmodels">
                <Link to="/Cab-Models"><i className="icon icon-setting" />
                  <IntlMessages id="sidebar.CabModels" />
                </Link>
              </Menu.Item>
              :
                ''
              }

              {((access && access.indexOf("Account Setting") > -1) || this.props.signin_type === 'Super_Admin')
                  ?
              <Menu.Item key="accountsettings">
                <Link to="/Account-Settings"><i className="icon icon-setting" />
                  <IntlMessages id="sidebar.AccountSettings" />
                </Link>
              </Menu.Item>
               :
                ''
              }

            </SubMenu>

            :
              ''
            }

            {(this.props.signin_type === 'User')
            ?
            <SubMenu className="Subb-Menu"
              key="sub19"
              title={
                <span>
                  <i className="icon icon-setting" />
                  <span>Manuals</span>
                </span>
              }
            >
               <Menu.Item key="link" >
                 <a href= "https://admin.eridehome.com/api/uploads/User_manual-eRidehome_App-Version1.pdf" target="_blank" rel="noopener noreferrer">
                  User App Manual
                 </a>
               </Menu.Item>

            </SubMenu>
            :
            (this.props.signin_type === 'Super_Admin')
            ?
            <SubMenu className="Subb-Menu"
              key="sub19"
              title={
                <span>
                  <i className="icon icon-setting" />
                  <span>Manuals</span>
                </span>
              }
            >
               <Menu.Item key="link" >
                 <a href= "https://admin.eridehome.com/api/uploads/Driver_manual-eRidehome_App-Version1.pdf" target="_blank" rel="noopener noreferrer">
                  Driver App Manual
                 </a>
               </Menu.Item>

               <Menu.Item key="link" >
                 <a href= "https://admin.eridehome.com/api/uploads/User_manual-eRidehome_App-Version1.pdf" target="_blank" rel="noopener noreferrer">
                  User App Manual
                 </a>
               </Menu.Item>

               <Menu.Item key="link" >
                 <a href= "https://admin.eridehome.com/api/uploads/Web_Admin_Manual-eRidehome-Documentation-1-converted.pdf" target="_blank" rel="noopener noreferrer">
                  Web Admin Manual
                 </a>
               </Menu.Item>

            </SubMenu>
            :
            (this.props.signin_type === 'Driver')
            ?
            <SubMenu className="Subb-Menu"
              key="sub19"
              title={
                <span>
                  <i className="icon icon-setting" />
                  <span>Manuals</span>
                </span>
              }
            >
              <Menu.Item key="link" >
                 <a href= "https://admin.eridehome.com/api/uploads/Driver_manual-eRidehome_App-Version1.pdf" target="_blank" rel="noopener noreferrer">
                  Driver App Manual
                 </a>
               </Menu.Item>
            </SubMenu>
            :
            ''

          }
          
          

          </Menu>
          
        </CustomScrollbars>
      </div>
    </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser, signin_id, signin_type, access } = auth;
  return { navStyle, themeType, locale, pathname, authUser, signin_id, signin_type, access }
};

/*const mapStateToProps = ({ auth }) => {
  const {  authUser, signin_id, signin_type, access } = auth;
  return { authUser, signin_id, signin_type, access }
};*/

export default connect(mapStateToProps, {
  userSignIn,
  userSignOut
})(SidebarContent);

//export default connect(mapStateToProps)(SidebarContent);
