import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}Dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}Add-Driver`} component={asyncComponent(() => import('./AddDriver'))}/>
      <Route path={`${match.url}Driver-List`} component={asyncComponent(() => import('./AddDriver/driver-list'))}/>
      <Route path={`${match.url}Accept-Reject-Driver`} component={asyncComponent(() => import('./AddDriver/accept-reject-driver'))}/>
      <Route path={`${match.url}Block-UnBlock-Driver`} component={asyncComponent(() => import('./AddDriver/block-unblock-driver'))}/>
      <Route path={`${match.url}Driver-Rating-History`} component={asyncComponent(() => import('./AddDriver/driver-rating'))}/>
      <Route path={`${match.url}Profile-Overview`} component={asyncComponent(() => import('./AddDriver/profile-overview'))}/>
      <Route path={`${match.url}CabType`} component={asyncComponent(() => import('./CabType'))}/>
      <Route path={`${match.url}Passenger-List`} component={asyncComponent(() => import('./Passengers/all-passengers'))}/>
      <Route path={`${match.url}Passenger-Profile-Overview`} component={asyncComponent(() => import('./Passengers/passenger-profile-overview'))} />
      <Route path={`${match.url}Passenger-Rating-History`} component={asyncComponent(() => import('./Passengers/passenger-rating'))}/>
      <Route path={`${match.url}My-Referrals`} component={asyncComponent(() => import('./Passengers/my-referrals'))}/>
      <Route path={`${match.url}Time-Management`} component={asyncComponent(() => import('./TimeManagement/time-management'))} />
      <Route path={`${match.url}Commission-Percentage`} component={asyncComponent(() => import('./FareManagement/commissionpercentage'))} />
      <Route path={`${match.url}sampleform`} component={asyncComponent(() => import('./SamplePage/sampleform'))} />
      <Route path={`${match.url}Coupon-Generation`} component={asyncComponent(() => import('./CouponGeneration/coupon-generation'))} />
      <Route path={`${match.url}Coupon-List`} component={asyncComponent(() => import('./CouponGeneration/coupon-list'))} />
      <Route path={`${match.url}Total-Income-Drivers`} component={asyncComponent(() => import('./IncomeDetails/total-income-drivers'))} />
      <Route path={`${match.url}Driver-Wise-Income`} component={asyncComponent(() => import('./IncomeDetails/driver_wise_income'))} />
      <Route path={`${match.url}Driver-Update-Request`} component={asyncComponent(() => import('./ProfileUpdates/driver-update-request'))} />
      <Route path={`${match.url}Profile-Changes`} component={asyncComponent(() => import('./ProfileUpdates/profile-changes'))} />
      <Route path={`${match.url}Active-Trips`} component={asyncComponent(() => import('./TripsDetails/active-trips'))} />
      <Route path={`${match.url}Completed-Trips`} component={asyncComponent(() => import('./TripsDetails/completed-trips'))} />
      <Route path={`${match.url}Completed-Trips-Dev`} component={asyncComponent(() => import('./TripsDetails/completed-trips-dev'))} />
      <Route path={`${match.url}Booked-Trips`} component={asyncComponent(() => import('./TripsDetails/booked-trips'))} />
      <Route path={`${match.url}Trip-Details`} component={asyncComponent(() => import('./TripsDetails/Trip-Details'))} />
      <Route path={`${match.url}Trip-Details-Dev`} component={asyncComponent(() => import('./TripsDetails/Trip-Details-Dev'))} />
      <Route path={`${match.url}Payment-Report`} component={asyncComponent(() => import('./Report/payment-report'))} />
      <Route path={`${match.url}Trip-History`} component={asyncComponent(() => import('./Report/trip-history'))} />
      <Route path={`${match.url}Active-Trips-History`} component={asyncComponent(() => import('./Report/active-trips-history'))} />
      <Route path={`${match.url}Cancelled-Trips`} component={asyncComponent(() => import('./Report/cancelled-trips'))} />
      <Route path={`${match.url}Trip-Request-Reject`} component={asyncComponent(() => import('./Report/trip-request-reject'))} />
      <Route path={`${match.url}Driver-Profile-Report`} component={asyncComponent(() => import('./Report/driver-profile-report'))} />
      <Route path={`${match.url}User-E-Wallet-Report`} component={asyncComponent(() => import('./Report/user-e-eallet-report'))} />
      <Route path={`${match.url}Add-Passenger`} component={asyncComponent(() => import('./Passengers/AddPassenger'))} />
      <Route path={`${match.url}User-Rating-Reviews`} component={asyncComponent(() => import('./RatingReviews/user-rating-review'))} />
      <Route path={`${match.url}Driver-Rating-Reviews`} component={asyncComponent(() => import('./RatingReviews/driver-rating-review'))} />
      <Route path={`${match.url}Account-Settings`} component={asyncComponent(() => import('./Settings/account-settings'))} />
      <Route path={`${match.url}Cab-Models`} component={asyncComponent(() => import('./Settings/cabmodels'))} />
      <Route path={`${match.url}Cab-Makes`} component={asyncComponent(() => import('./Settings/cabmakes'))} />
      <Route path={`${match.url}Send-Notifications`} component={asyncComponent(() => import('./Notifications/send-notifications'))} />
      <Route path={`${match.url}Dispute-Type`} component={asyncComponent(() => import('./Dispute/dispute-type'))} />
      <Route path={`${match.url}Dispute-Request`} component={asyncComponent(() => import('./Dispute/dispute-request'))} />
      <Route path={`${match.url}Overall-Ride-Statement`} component={asyncComponent(() => import('./Statements/overall-ride-statement'))} />
      <Route path={`${match.url}Driver-Statement`} component={asyncComponent(() => import('./Statements/driver-statements'))} />
      <Route path={`${match.url}Driverwise-Statement`} component={asyncComponent(() => import('./Statements/driverwise_statement'))} />
      <Route path={`${match.url}Passenger-Statement`} component={asyncComponent(() => import('./Statements/user-statements'))} />
      <Route path={`${match.url}Passengerwise-Statement`} component={asyncComponent(() => import('./Statements/passengerwise-statement'))} />
      <Route path={`${match.url}Driver-Location-Map`} component={asyncComponent(() => import('./TripsDetails/driver-location'))} />
      <Route path={`${match.url}Add-User-Group`} component={asyncComponent(() => import('./UserGroups/add-user-group'))} />
      <Route path={`${match.url}Manage-User-Group`} component={asyncComponent(() => import('./UserGroups/manage-user-group'))} />
      <Route path={`${match.url}Add-Subadmin`} component={asyncComponent(() => import('./UserGroups/add-subadmin'))} />
      <Route path={`${match.url}Manage-Subadmin`} component={asyncComponent(() => import('./UserGroups/manage-subadmin'))} />
      <Route path={`${match.url}User-KYC-Verify`} component={asyncComponent(() => import('./ProfileUpdates/user-kyc-verify'))} />
      <Route path={`${match.url}User-App-Manual`} component={asyncComponent(() => import('./Settings/User_manual-eRidehome_App-Version1.pdf'))} />
      <Route path={`${match.url}Add-Employee`} component={asyncComponent(() => import('./Company/add-employee'))} />
      <Route path={`${match.url}Manage-Employee`} component={asyncComponent(() => import('./Company/manage-employee'))} />
      <Route path={`${match.url}Add-NewCard`} component={asyncComponent(() => import('./Company/add-new-card'))} />
      <Route path={`${match.url}Manage-Cards`} component={asyncComponent(() => import('./Company/manage-cards'))} />
      <Route path={`${match.url}Transaction`} component={asyncComponent(() => import('./Company/transactions'))} />
      <Route path={`${match.url}Company-List`} component={asyncComponent(() => import('./Company/company-list'))} />
      <Route path={`${match.url}Transaction-Details`} component={asyncComponent(() => import('./Company/transaction-details'))} />
      <Route path={`${match.url}Edit-Company`} component={asyncComponent(() => import('./Company/edit-company'))} />
      <Route path={`${match.url}Driver-CDC-Report`} component={asyncComponent(() => import('./Report/driver-cdc-report'))} />
      <Route path={`${match.url}User-CDC-Report`} component={asyncComponent(() => import('./Report/user-cdc-report'))} />
    </Switch>
  </div>
);

export default App;
