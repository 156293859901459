import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { Link } from "react-router-dom";
import { userSignIn, userSignOut, userSignOutSuccess } from "appRedux/actions/Auth";

class UserProfile extends Component {

  componentDidMount() { 
    if (this.props.signin_id === undefined || this.props.signin_id === '' || this.props.signin_id === 'null') {
      this.props.userSignOut()
    }
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>
          <Link to="/Account-Settings" className="setting_class">My Account</Link>
        </li>
        <li onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    );

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src='https://image.flaticon.com/icons/svg/206/206883.svg'
            className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span className="gx-avatar-name">{this.props.signin_name}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>

    )

  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, signin_id, signin_type, signin_name } = auth;
  return { loader, alertMessage, showMessage, authUser, signin_id, signin_type, signin_name }
};

export default connect(mapStateToProps, {
  userSignIn,
  userSignOut
})(UserProfile);

//export default connect(null, { userSignOut })(UserProfile);
