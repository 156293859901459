import React from "react";
import {Scrollbars} from "react-custom-scrollbars";

// const CustomScrollbars = (props) => <Scrollbars  {...props} autoHide
//                                                  renderTrackHorizontal={props => <div {...props}
//                                                                                       style={{display: 'none'}}
//                                                                                       className="track-horizontal"/>}/>;
const CustomScrollbars = (props) => <Scrollbars {...props} autoHide
renderTrackHorizontal={props => <div {...props}
style={{display: 'none'}}
className="track-horizontal"/>}
renderTrackVertical={props => <div {...props} className="track-vertical"/>}
renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
renderView={props => <div {...props} className="view"/>}/>;

export default CustomScrollbars;
